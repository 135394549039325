// import type { InputRef } from "antd";
import {
  Button,
  Input,
  Popconfirm,
  Table,
  Select,
  InputNumber,
  Spin,
} from "antd";
import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  InventoryPreapproval,
  InventoryState,
} from "../../redux/reducers/InventoryReducer";
import {
  calculateFees,
  calculateFeesFromAPI,
  checkPreapprovalStatusFromAPI,
} from "../../redux/actions/inventoryActions";
import { getDropDownValuesForConsigner } from "../../screens/InventoryItem";
import { UserState } from "../../redux/reducers/UserReducer";
import styled from "styled-components";
import { TextField, IconButton, ButtonGroup } from "@mui/material";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import Colors from "../../constants/Colors";
import { AppState } from "../../redux/reducers/AppReducer";
import getSymbolFromCurrency from "currency-symbol-map";
import { StoreState } from "../../redux/reducers/StoreReducer";

const { Option } = Select;

interface VariantsPrices {
  option1Value: string;
  option2Value?: string;
  option3Value?: string;
  price: string;
}

interface Props {
  inventoryList: any;
  setInventoryList: any;
  productTemplate: any;
  option1Value: any;
  setOption1Value: any;
  setCreatedInventory: any;
  id: string;
  lowestPrices: VariantsPrices[];
}

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const ModalTableReportSpan = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 8px 16px;
  b {
    display: block;
    width: 100px;
    text-align: right;
  }
`;

const StyledInventoryAddModalTable = styled(Table)`
  .ant-table-thead .ant-table-cell {
    background-color: #f3f4f6;
    color: #2e2e2e;
  }

  .ant-table-tbody .ant-table-cell {
    vertical-align: top;
  }

  // .ant-input-number {
  //   border: none;
  // }

  // .ant-select .ant-select-selector {
  //   border: none;
  // }
`;

const ListingSpan = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 8px auto;
  label {
    width: 100%;
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    margin: 10px 0 5px;
  }
  .ant-input-number {
    width: 100%;
    padding: 8px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    padding: 8px;
    height: auto;
  }
  @media (max-width: 768px) {
    .ant-input-number input {
      font-size: 16px;
    }
  }
`;

const ComputationSpan = styled.span`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border: 1px solid var(--stroke-color, #e4e4e7);
  background: var(--base-white, #fff);
  justify-content: space-between;
  p {
    color: ${Colors.CONSIGNER_GREY};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 216.667% */
    margin: 0;
  }
  b {
    color: var(--heading-color, #2e2e2e);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 216.667% */
  }
`;

const StyledInputNumber = styled(InputNumber)`
  @media (max-width: 768px) {
    .ant-input-number-handler-wrap {
      display: flex;
    }
  }
`;

const Heading = styled.h2`
  color: ${Colors.CONSIGNER_BLUE};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 162.5% */
  text-transform: capitalize;
  text-align: left;
`;

const MuiInputNumber = (props: any) => {
  const { value, onChange, id, min } = props;

  const handleIncrement = () => {
    onChange(value + 1);
  };

  const handleDecrement = () => {
    const newVal = value - 1 < min ? min : value - 1;
    onChange(newVal);
  };

  return (
    <TextField
      type="number"
      value={value}
      id={id}
      onChange={(e) => onChange(Number(e.target.value))}
      InputProps={{
        startAdornment: (
          <IconButton onClick={handleDecrement}>
            <MinusCircleOutlined />
          </IconButton>
        ),
        endAdornment: (
          <IconButton onClick={handleIncrement}>
            <PlusCircleOutlined />
          </IconButton>
        ),
      }}
    />
  );
};

export const ListingInventoryView = (props: any) => {
  const {
    inventoryList,
    handleChange,
    setInventoryList,
    productTemplate,
    option1Value,
    setOption1Value,
    setCreatedInventory,
    store,
    applyStatusToAll,
    applyPriceToAll,
    consignersForDropdown,
    getVariantLowestPrice,
  } = props;
  const [itemRefs, setItemRefs] = useState(
    inventoryList.map(() => createRef<HTMLDivElement>())
  );
  useEffect(() => {
    setItemRefs(inventoryList.map(() => createRef<HTMLDivElement>()));
  }, [inventoryList]);
  const scrollToNextElement = (currentIndex) => {
    if (currentIndex < inventoryList.length - 1) {
      itemRefs[currentIndex + 1].current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      {inventoryList.map((record, key) => (
        <div
          key={key}
          ref={itemRefs[key]}
          style={{
            display: "flex",
            padding: 12,
            border: "1px solid var(--stroke-color, #E4E4E7)",
            margin: "24px auto",
            flexDirection: "column",
          }}
        >
          <Heading>Listing Details of {record.option1Value}</Heading>
          <ListingSpan>
            <label htmlFor="quantity">Quantity</label>
            {/* {isMobile ? (
              <MuiInputNumber
                id="quantity"
                value={record.quantity}
                onChange={(value: any) =>
                  handleChange(value, record, "quantity")
                }
                min={1}
              />
            ) : ( */}
            <StyledInputNumber
              id="quantity"
              value={record.quantity}
              onChange={(value: any) => handleChange(value, record, "quantity")}
              min={1}
            />
            {/* )} */}
          </ListingSpan>
          {process.env.REACT_APP_TYPE !== "consigner" && (
            <ListingSpan>
              <label htmlFor="consigner">Consigner</label>
              <Select
                showSearch
                disabled={process.env.REACT_APP_TYPE === "consigner"}
                style={{ width: "100%" }}
                onChange={(value) => handleChange(value, record, "consigner")}
                defaultValue={record.consigner}
                filterOption={(input, option) =>
                  String(option?.children)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {consignersForDropdown &&
                  consignersForDropdown.length > 0 &&
                  consignersForDropdown.map((item: any, index: any) => (
                    <Option key={index} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
              </Select>
            </ListingSpan>
          )}
          <ListingSpan>
            <label htmlFor="option2Value">Condition</label>
            {productTemplate.option2Values ? (
              <div style={{ display: "flex" }}>
                <Select
                  style={{ flex: 1 }}
                  value={
                    inventoryList.find(
                      (inventory) => inventory.key === record.key
                    ).option2Value
                  }
                  onChange={(value) =>
                    handleChange(value, record, "option2Value")
                  }
                  id="option2Value"
                  filterOption={(input, option) =>
                    String(option?.children)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {productTemplate.option2Values.map(
                    (template: any, index: any) => (
                      <Option key={index} value={template}>
                        {template}
                      </Option>
                    )
                  )}
                </Select>
                {/* <Popconfirm
                  title="Apply this condition to all products?"
                  onConfirm={() => applyStatusToAll(record.option2Value)}
                  icon={() => null}
                  style={{ padding: "36px 10px" }}
                  okText="Continue"
                > */}
                <Button
                  style={{ flex: "0 0 48px", height: "auto" }}
                  type="primary"
                  onClick={() => applyStatusToAll(record.option2Value, key)}
                  icon={<ArrowDownOutlined />}
                ></Button>
                {/* </Popconfirm> */}
              </div>
            ) : (
              ""
            )}
          </ListingSpan>
          {productTemplate.option3Values &&
          productTemplate.option3Values.length > 0 ? (
            <ListingSpan>
              <label htmlFor="option3Value">Location</label>
              <Select
                style={{ width: "100%" }}
                onChange={(value) =>
                  handleChange(value, record, "option3Value")
                }
                filterOption={(input, option) =>
                  String(option?.children)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {productTemplate.option3Values.map(
                  (template: any, index: any) => (
                    <Option key={index} value={template}>
                      {template}
                    </Option>
                  )
                )}
              </Select>
            </ListingSpan>
          ) : (
            <></>
          )}
          <ListingSpan>
            <Heading style={{ color: "#2E2E2E" }}>Listing Price</Heading>
            <div style={{ display: "flex" }}>
              {/* {isMobile ? (
                <MuiInputNumber
                  style={{flex: 1}}
                  id="price"
                  value={record.price}
                  onChange={(value: any) => handleChange(value, record, "price")}
                  min={0}
                />
              ) : ( */}
              <StyledInputNumber
                style={{ flex: 1 }}
                id="price"
                value={record.price}
                onChange={(value: any) => handleChange(value, record, "price")}
                min={0}
              />
              {/* )} */}
              <Button
                style={{ flex: "0 0 48px", height: "auto" }}
                type="primary"
                icon={<ArrowDownOutlined />}
                onClick={() => applyPriceToAll(record.price, record.key)}
              ></Button>
            </div>
            {process.env.REACT_APP_TYPE === "consigner" &&
              (+record.min > 0 || +record.max > 0) && (
                <p
                  style={{
                    color: "var(--input-field, rgba(88, 85, 85, 0.30))",
                    textAlign: "left",
                    fontSize: 12,
                    fontStyle: "normal",
                    fontWeight: 400,
                    marginTop: 2,
                    opacity: record.min > 0 || record.max > 0 ? 1 : 0,
                  }}
                >
                  Preapproval Range: {getSymbolFromCurrency(store.currency)}
                  {record.min}-{record.max}
                </p>
              )}
            {process.env.REACT_APP_TYPE === "consigner" && record.priceAlert && (
              <p
                style={{
                  textAlign: "left",
                  fontSize: 12,
                  fontStyle: "normal",
                  fontWeight: 400,
                  marginTop: 2,
                  marginBottom: 0,
                  color: "goldenrod",
                  padding: 3,
                }}
              >
                {store.warnText} Instant accepts at (
                {getSymbolFromCurrency(store.currency)}
                {getVariantLowestPrice(
                  record.option1Value,
                  record.option2Value,
                  record.option3Value
                )}
                ).
              </p>
            )}
          </ListingSpan>
          <ComputationSpan>
            <p>Payout Fee</p>
            <b>
              {getSymbolFromCurrency(store.currency)}{" "}
              {record?.payoutFee * record?.quantity}
            </b>
          </ComputationSpan>
          <ComputationSpan>
            <p>Payout/Items</p>
            <b>
              {getSymbolFromCurrency(store.currency)}
              {Number(record?.payout ?? 0) + Number(record.payoutFee)}
            </b>
          </ComputationSpan>
          {/* <ComputationSpan>
            <p>Subtotal</p>
            <b>
              {getSymbolFromCurrency(store.currency)} {Number(record?.payout ?? 0).toFixed(2)}
            </b>
          </ComputationSpan> */}
          <ComputationSpan>
            <p>Total</p>
            <b>
              {getSymbolFromCurrency(store.currency)}
              {Number((record?.payout ?? 0) * record.quantity).toFixed(2)}
            </b>
          </ComputationSpan>
          <ButtonGroup
            style={{ display: "flex", gap: 8, height: 52, marginTop: 24 }}
          >
            <Button
              style={{ flex: 1, height: "auto" }}
              type="ghost"
              onClick={() => {
                setInventoryList(
                  inventoryList.filter((inventory) => inventory.key !== key)
                );
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ flex: 1, height: "auto" }}
              type="primary"
              onClick={() => scrollToNextElement(key)}
            >
              Done
            </Button>
          </ButtonGroup>
        </div>
      ))}
    </div>
  );
};

const InventoryAddModalTable = (props: Props) => {
  const {
    inventoryList,
    setInventoryList,
    productTemplate,
    option1Value,
    setOption1Value,
    setCreatedInventory,
    id,
    lowestPrices,
  } = props;

  const { calculatedFees }: InventoryState = useAppSelector(
    (state) => state.InventoryReducer
  );

  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  const dispatch = useAppDispatch();
  const [signalCalcFees, setSignalCalcFees] = useState<AbortController | null>(
    null
  );

  const {
    inventoryFilterOptions,
    createdInventory,
    inventoryFilterOptionsLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);
  const [consignersForDropdown, setConsignersForDropdown] = useState([]);

  const checkPreapprovalStatus = (
    option1Value = "",
    option2Value = "",
    option3Value = ""
  ): { min: number; max: number; preapproval: boolean } => {
    const { minApproval: min, maxApproval: max, autoPreapproval } = store;
    let minValue = 0,
      maxValue = 0;
    if (!autoPreapproval) return { min: 0, max: 0, preapproval: false };
    const record = lowestPrices.find(
      (variant) =>
        variant.option1Value === option1Value &&
        variant.option2Value ===
          option2Value /* && variant.option3Value === option3Value */
    );
    if (!record) {
      return {
        preapproval: autoPreapproval,
        min: 0,
        max: 0,
      };
    }
    const price = Number(record?.price ?? 0);
    minValue = price - Number(min);
    maxValue = price + Number(max);

    if (Number(min) === 0 && Number(max) === 0)
      return { preapproval: true, min: minValue, max: maxValue };
    const preapproval =
      price + Number(max) >= price && price - Number(min) <= price;

    return {
      preapproval,
      min: minValue,
      max: maxValue,
    };
  };

  const checkForPriceAlert = (
    option1Value = "",
    option2Value = "",
    option3Value = "",
    price = 0
  ): boolean => {
    if (Number(store.warnAmount) === 0) return false;
    const prc =
      lowestPrices.find(
        (variant) =>
          variant.option1Value === option1Value &&
          variant.option2Value ===
            option2Value /*  && variant.option3Value === option3Value */
      )?.price ?? 0;
    const warnAmount = Number(store?.warnAmount ?? 0);
    if (+prc - warnAmount <= 0 || prc === 0) return false;
    if (price > +prc - warnAmount) return true;
    return false;
  };

  const fetchPreapprovalData = useMemo(() => {
    const cache = new Map();

    return async (id, price, opt1, opt2, opt3, con) => {
      if (process.env.REACT_APP_TYPE === "employee") {
        // items added by admin are always preapproved
        return { min: 0, max: 0, preapproval: true };
      }
      const consigner = JSON.parse(con); //consigner object is too long
      const cacheKey = `${id}_${price}_${opt1}_${opt2}_${consigner.id}`;
      if (cache.has(cacheKey)) {
        console.info("Using cached data", cacheKey);
        return cache.get(cacheKey);
      }

      try {
        /* const data = await checkPreapprovalStatusFromAPI(
          id,
          price,
          opt1,
          opt2,
          opt3,
          con
        ); */
        const data = checkPreapprovalStatus(opt1, opt2, opt3);
        cache.set(cacheKey, data);
        return data;
      } catch (error) {
        console.warn("Error fetching data: ", error);
      }
    };
  }, [lowestPrices]);

  const applyStatusToAll = async (option2Value: string, key = 0) => {
    const newData = await Promise.all(
      inventoryList.map(async (updateItem) => {
        if (updateItem.option2Value !== option2Value) {
          const preapproval = await fetchPreapprovalData(
            id,
            Number(updateItem.price),
            updateItem.option1Value,
            option2Value,
            updateItem.option3Value,
            updateItem.consigner
          );
          const priceAlert = checkForPriceAlert(
            updateItem.option1Value,
            option2Value,
            updateItem.option3Value,
            updateItem.price
          );
          const upData = {
            ...updateItem,
            option2Value,
            min: preapproval?.min ?? 0,
            max: preapproval?.max ?? 0,
            priceAlert,
          };
          return upData;
        }
        return updateItem;
      })
    );
    setInventoryList(newData);
  };

  const fetchFees = useMemo(() => {
    const cache = new Map();

    return async (id, price, qty, opt1, opt2, opt3, cost, cat, con) => {
      const consigner = JSON.parse(con); //consigner object is too long
      const cacheKey = `${id}_${price}_${qty}_${opt1}_${opt2}_${opt3}_${cost}_${cat}_${consigner.id}`;

      if (cache.has(cacheKey)) {
        console.info("Using cached data", cacheKey);
        return cache.get(cacheKey);
      }

      try {
        const data = await calculateFeesFromAPI(
          id,
          price,
          qty,
          opt1,
          opt2,
          opt3,
          cost,
          cat,
          con
        );
        cache.set(cacheKey, data);
        return data;
      } catch (error) {
        console.warn("Error fetching data: ", error);
      }
    };
  }, []);

  const applyPriceToAll = async (price = 0, key = 0) => {
    const newData = await Promise.all(
      inventoryList.map(async (updateItem) => {
        if (Number(updateItem.price) !== price) {
          let cost = 0;
          let consigner;
          if (updateItem.consigner) {
            consigner = JSON.parse(updateItem.consigner);
            if (consigner.isStoreAccount && updateItem.payout) {
              cost = updateItem.payout;
              updateItem.cost = updateItem.payout;
            }
          }
          const calculatedFee = await fetchFees(
            updateItem.id,
            price,
            updateItem.quantity,
            updateItem.option1Value,
            updateItem.option2Value,
            updateItem.option3Value,
            Number(cost),
            updateItem.category,
            updateItem.consigner
          );
          const payout = String(calculatedFee?.payout ?? 0);
          const payoutFee = String(calculatedFee?.payoutFee ?? 0);
          const total = String(calculatedFee?.total ?? 0);
          const priceAlert = checkForPriceAlert(
            updateItem.option1Value,
            updateItem.option2Value,
            updateItem.option3Value,
            price
          );
          const upData = {
            ...updateItem,
            price,
            payout,
            payoutFee,
            priceAlert,
            total,
          };
          return upData;
        }
        return updateItem;
      })
    );
    setInventoryList(newData);
  };
  const applyLocationToAll = async (option3Value: string, key = 0) => {
    const newData = await Promise.all(
      inventoryList.map(async (updateItem) => {
        if (updateItem.option3Value !== option3Value) {
          const upData = {
            ...updateItem,
            option3Value: option3Value,
          };
          return upData;
        }
        return updateItem;
      })
    );
    setInventoryList(newData);
  };
  useEffect(() => {
    setCreatedInventory(createdInventory);
  }, [createdInventory]);

  useEffect(() => {
    let result: any;
    if (process.env.REACT_APP_TYPE === "consigner" && dbUser) {
      let consigner = [
        {
          label: `${dbUser.firstName} ` + `${dbUser.lastName} `,
          value: dbUser,
        },
      ];
      result = getDropDownValuesForConsigner(consigner);
    } else if (inventoryFilterOptions && inventoryFilterOptions.consigners) {
      result = getDropDownValuesForConsigner(inventoryFilterOptions.consigners);
    }
    setConsignersForDropdown((res) => result);
  }, [inventoryFilterOptions]);

  //add new row when option1Value changes
  useEffect(() => {
    if (option1Value && dbUser) {
      let defaultConsigner = [
        {
          label: `${dbUser.firstName} ` + `${dbUser.lastName} `,
          value: dbUser,
        },
      ];
      if (process.env.REACT_APP_TYPE === "employee") {
        defaultConsigner = inventoryFilterOptions.consigners.filter(
          (consigner: any) => consigner.value.isStoreAccount === true
        );
      }
      let formatedConsigner = getDropDownValuesForConsigner(defaultConsigner);
      const newData = {
        key: count,
        quantity: 1,
        option1Value,
        option2Value: "",
        option3Value: "",
        payoutFee: 0,
        price: 0,
        cost: 0,
        consigner:
          formatedConsigner?.length && formatedConsigner[0].value
            ? formatedConsigner[0].value
            : "",
        category: productTemplate.category,
      };
      setInventoryList([...inventoryList, newData]);
      setCount(count + 1);
      setOption1Value("");
    }
  }, [option1Value]);

  const [updatedValue, setUpdatedValue] = useState<any>({});
  const [variableChange, setVariableChange] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (updatedValue && inventoryList.length > 0) {
      const newData = [...inventoryList];
      const index = newData.findIndex((item) => updatedValue.key === item.key);
      const item = newData[index];

      //re fetches fees on price and quantity changes

      if (
        variableChange == "price" ||
        variableChange == "quantity" ||
        variableChange == "payout" ||
        (variableChange == "consigner" && updatedValue.price !== 0)
      ) {
        console.log(calculatedFees, Number(calculatedFees?.payout));
        if (calculatedFees && !isNaN(Number(calculatedFees?.payout))) {
          updatedValue.payout = String(calculatedFees.payout ?? 0);
          updatedValue.payoutFee = String(calculatedFees.payoutFee ?? 0);
          updatedValue.total = String(calculatedFees.total ?? 0);
          setSignalCalcFees(null);
        }
      }
      if (variableChange === "price") {
        const priceAlert = checkForPriceAlert(
          item.option1Value,
          item.option2Value,
          item.option3Value,
          updatedValue.price
        );
        updatedValue.priceAlert = priceAlert;
      }

      newData.splice(index, 1, {
        ...item,
        ...updatedValue,
      });

      setInventoryList(newData);
    }
  }, [updatedValue, calculatedFees]);

  const handleDelete = (key: React.Key) => {
    const newData = inventoryList.filter((item: any) => item.key !== key);
    setInventoryList(newData);
  };

  const handleChange = async (
    value: any,
    record: any,
    variableChanged: any
  ) => {
    const updateItem = {
      ...record,
      [variableChanged]: value,
    };

    let cost = 0;
    let consigner;
    let preapprovalData: InventoryPreapproval;
    if (updateItem.consigner) {
      consigner = JSON.parse(updateItem.consigner);
      if (consigner.isStoreAccount && updateItem.payout) {
        cost = updateItem.payout;
        updateItem.cost = updateItem.payout;
      }
    }

    if (
      variableChanged === "option2Value" &&
      process.env.REACT_APP_TYPE === "consigner"
    ) {
      preapprovalData = await fetchPreapprovalData(
        id,
        Number(updateItem.price),
        updateItem.option1Value,
        updateItem.option2Value,
        updateItem.option3Value,
        JSON.stringify(updateItem.consigner ?? dbUser)
      );
      updateItem.min = preapprovalData.min;
      updateItem.max = preapprovalData.max;
    }
    if (variableChange === "price") {
      const priceAlert = checkForPriceAlert(
        updateItem.option1Value,
        updateItem.option2Value,
        updateItem.option3Value,
        updateItem.price
      );
      updateItem.priceAlert = priceAlert;
    }

    if (
      (variableChanged == "price" ||
        variableChanged == "quantity" ||
        variableChanged == "payout" ||
        (variableChanged == "consigner" && record.price !== 0)) &&
      updateItem.consigner
    ) {
      if (signalCalcFees) {
        signalCalcFees.abort();
      }
      const controller = new AbortController();
      setSignalCalcFees(controller);
      dispatch(
        calculateFees(
          updateItem.id,
          Number(updateItem.price),
          updateItem.quantity,
          updateItem.option1Value,
          updateItem.option2Value,
          updateItem.option3Value,
          Number(cost),
          updateItem.category,
          updateItem.consigner,
          controller
        )
      );
    }
    setVariableChange(variableChanged);
    setUpdatedValue(updateItem);
  };

  const { Search } = Input;

  const columns: //ColumnsType<DataType> = [
  (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: `${productTemplate && productTemplate.option1}`,
      dataIndex: "option1Value",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      render: (_: any, record: any) => (
        <StyledInputNumber
          value={record.quantity}
          onChange={(value: any) => handleChange(value, record, "quantity")}
          min={1}
        />
      ),
    },
    {
      title: store.isBuyingStore ? "Seller" : "Consigner",
      dataIndex: "consigner",
      render: (_: any, record: any) => (
        <>
          <Select
            showSearch
            disabled={process.env.REACT_APP_TYPE === "consigner"}
            style={{ width: "100%" }}
            onChange={(value) => handleChange(value, record, "consigner")}
            defaultValue={record.consigner}
            filterOption={(input, option) =>
              String(option?.children)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {consignersForDropdown &&
              consignersForDropdown.length > 0 &&
              consignersForDropdown.map((item: any, index: any) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
          </Select>
        </>
      ),
    },
    {
      title: `${productTemplate && productTemplate.option2}`,
      dataIndex: "option2Value",
      render: (_: any, record: any) => (
        <>
          {productTemplate.option2Values ? (
            <div style={{ display: "flex" }}>
              <Select
                style={{ flex: 1 }}
                value={
                  inventoryList.find(
                    (inventory) => inventory.key === record.key
                  ).option2Value
                }
                onChange={(value) =>
                  handleChange(value, record, "option2Value")
                }
                filterOption={(input, option) =>
                  String(option?.children)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {productTemplate.option2Values.map(
                  (template: any, index: any) => (
                    <Option key={index} value={template}>
                      {template}
                    </Option>
                  )
                )}
              </Select>
              <Button
                style={{ flex: "0 0 48px", height: "auto" }}
                type="primary"
                icon={<ArrowDownOutlined />}
                onClick={() =>
                  applyStatusToAll(record.option2Value, record.key)
                }
              ></Button>
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: `${productTemplate && productTemplate.option3}`,
      dataIndex: "option3Value",
      render: (_: any, record: any) => (
        <>
          {productTemplate.option3Values ? (
            <div style={{ display: "flex" }}>
              <Select
                style={{ width: "100%" }}
                onChange={(value) =>
                  handleChange(value, record, "option3Value")
                }
                filterOption={(input, option) =>
                  String(option?.children)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={
                  inventoryList.find(
                    (inventory) => inventory.key === record.key
                  ).option3Value
                }
              >
                {productTemplate.option3Values.map(
                  (template: any, index: any) => (
                    <Option key={index} value={template}>
                      {template}
                    </Option>
                  )
                )}
              </Select>
              <Button
                style={{ flex: "0 0 48px", height: "auto" }}
                type="primary"
                icon={<ArrowDownOutlined />}
                onClick={() =>
                  applyLocationToAll(record.option3Value, record.key)
                }
              />
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (_: any, record: any) => (
        <div style={{ position: "relative" }}>
          <div style={{ display: "flex" }}>
            <StyledInputNumber
              style={{
                flex: 1,
                border: record.priceAlert ? "2px solid gold" : "initial",
              }}
              value={record.price}
              onChange={(value: any) => handleChange(value, record, "price")}
              min={0}
            />
            <Button
              style={{ flex: "0 0 48px", height: "auto" }}
              type="primary"
              icon={<ArrowDownOutlined />}
              disabled={signalCalcFees !== null}
              onClick={() => applyPriceToAll(record.price, record.key)}
            ></Button>
          </div>
          {process.env.REACT_APP_TYPE === "consigner" &&
            (+record.min > 0 || +record.max > 0) && (
              <p
                style={{
                  color: "var(--input-field, rgba(88, 85, 85, 0.30))",
                  textAlign: "left",
                  fontSize: 12,
                  fontStyle: "normal",
                  fontWeight: 400,
                  marginTop: 2,
                  marginBottom: 0,
                  opacity: record.min > 0 || record.max > 0 ? 1 : 0,
                }}
              >
                Preapproval Range: {getSymbolFromCurrency(store.currency)}
                {record.min}-{record.max}
              </p>
            )}
          {process.env.REACT_APP_TYPE === "consigner" && record.priceAlert && (
            <p
              style={{
                textAlign: "left",
                fontSize: 12,
                fontStyle: "normal",
                fontWeight: 400,
                marginTop: 2,
                marginBottom: 0,
                color: "goldenrod",
                padding: 3,
              }}
            >
              {store.warnText} Instant accepts at (
              {getSymbolFromCurrency(store.currency)}
              {getVariantLowestPrice(
                record.option1Value,
                record.option2Value,
                record.option3Value
              )}
              )
            </p>
          )}
        </div>
      ),
    },
    {
      title: "Payout Fee",
      dataIndex: "payoutFee",
    },
    {
      title: "Cost or Payout",
      dataIndex: "payout",
      fixed: "right",

      render: (_: any, record: any) => (
        <StyledInputNumber
          disabled={process.env.REACT_APP_TYPE === "consigner"}
          value={record.payout}
          onChange={(value: any) => handleChange(value, record, "payout")}
        />
      ),
    },
    {
      title: `${
        process.env.REACT_APP_TYPE === "employee"
          ? "Total Cost or Payout"
          : "Total Payout"
      }`,
      dataIndex: "total",
      fixed: "right",
    },
    {
      title: "Delete",
      dataIndex: "operation",
      render: (_: any, record: any) =>
        inventoryList.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <Button type="primary" danger>
              Delete
            </Button>
          </Popconfirm>
        ) : null,
      fixed: "right",
    },
  ];

  let visibleColumns = columns;

  if (productTemplate && !productTemplate.option2 && !productTemplate.option3) {
    visibleColumns = columns.filter(
      (col) =>
        col.dataIndex !== "option2Value" && col.dataIndex !== "option3Value"
    );
  } else if (productTemplate && !productTemplate.option3) {
    visibleColumns = columns.filter((col) => col.dataIndex !== "option3Value");
  }
  if (process.env.REACT_APP_TYPE === "consigner") {
    visibleColumns = visibleColumns.filter(
      (col) => col.dataIndex !== "consigner" && col.dataIndex !== "payout"
    );
  }

  const getVariantLowestPrice = (
    option1Value = "",
    option2Value = "",
    option3Value = ""
  ): number => {
    const prc =
      lowestPrices.find(
        (variant) =>
          variant.option1Value === option1Value &&
          variant.option2Value ===
            option2Value /*  && variant.option3Value === option3Value */
      )?.price ?? 0;
    return Number(prc) - Number(store.warnAmount);
  };

  if (inventoryFilterOptionsLoading) return <Spin />;

  return (
    <div>
      {isMobile ? (
        <ListingInventoryView
          inventoryList={inventoryList}
          handleChange={handleChange}
          productTemplate={productTemplate}
          store={store}
          applyStatusToAll={applyStatusToAll}
          applyPriceToAll={applyPriceToAll}
          consignersForDropdown={consignersForDropdown}
          getVariantLowestPrice={getVariantLowestPrice}
        />
      ) : (
        <StyledInventoryAddModalTable
          className="InventoryAddModalTable"
          dataSource={inventoryList}
          columns={visibleColumns as ColumnTypes}
          size="small"
          pagination={false}
        />
      )}
      {inventoryList.every((inventory) => inventory.total) && (
        <ModalTableReportSpan>
          Total Payout:
          <b
            style={{
              color: "#2E2E2E",
              fontSize: 12,
            }}
          >
            {getSymbolFromCurrency(store.currency)}
            {inventoryList
              .reduce((sum, inventory) => sum + Number(inventory.total), 0)
              .toFixed(2)}
          </b>
        </ModalTableReportSpan>
      )}
    </div>
  );
};
export default InventoryAddModalTable;
